<template>
  <div class="addCase">
    <div class="top">
      <Steps :dataSource="list" :active="steps" class="steps"></Steps>
    </div>
    <div v-if="remark" class="shadow-box remark-box">
      <p class="remark-title">
        <van-icon class="remark-icon" name="warning" />
        驳回原因
      </p>
      <p class="remark-cont">{{ remark }}</p>
    </div>

    <div class="formBox" v-if="show">
      <template v-for="(item, index) in arr">
        <van-form class="from" :key="index">
          <div class="title">{{ item.module_name }}</div>
          <!-- 随访信息病例 -->
          <template v-if="is_followup && is_followup == 1 && steps == 0">
            <van-cell-group>
              <van-field v-if="patient_name" v-model="patient_name" label="姓名" readonly required />
              <van-field v-if="patient_idsn" v-model="patient_idsn" label="身份证号" readonly required />
            </van-cell-group>
          </template>
          <template v-for="(itm, idx) in item.input">
            <Configure :key="idx" :item="itm" :relation="getRelationShow(itm)" v-if="itm.type == 'configure'" :case_id="record_id" :preDetail="preDetail"></Configure>
            <Content :key="idx" :class="itm.explain_title && itm.explain_description ? 'no-border-bottom' : ''" @nextDisabled="nextDisabled" :item="itm" @Idsn="onIdsn" v-else :relation="getRelationShow(itm)" :BMIData="BMIData" :arr="arr" :case_id="record_id" @bzkyPickerConfirm="bzkyCustomPickConfirm" @isShowReason="getIsShowReason"></Content>
            <!-- <div class="bzky-text-btn" :key="itm.label" v-if="itm.explain_title && itm.explain_description" @click="handleShowBzkyPopup(itm)">
                            <template v-if="itm.explain_description!=''">
                                <p>{{ itm.explain_title }}</p>
                            </template>
                            <template>
                                <p>{{ itm.explain_description }}</p>
                            </template>
                        </div> -->
            <!-- <div class="bzky-text-btn" :key="itm.label" v-if="itm.explain_title && itm.explain_description" @click="handleShowBzkyPopup(itm)">
                            <p>{{ itm.explain_title }}</p>
                        </div> -->
            <template v-if="itm.explain_title && itm.explain_title != ''">
              <template v-if="itm.explain_description && itm.explain_description != ''">
                <div class="bzky-text-btn" :key="itm.label" v-if="itm.explain_title && itm.explain_description" @click="handleShowBzkyPopup(itm)">
                  <p>{{ itm.explain_title }}</p>
                </div>
              </template>
              <template v-else>
                <div class="bzky-text-btn" :key="itm.label">
                  <p class="black">{{ itm.explain_title }}</p>
                </div>
              </template>
            </template>
          </template>
          <!-- 靶向治疗二期只在第一阶段第一步显示切换按钮 -->
          <div class="more_text" v-if="steps == 0 && short_name == 'bxzleq' && phase == 1 && scheme_name != '胃癌'" @click="switchScheme()">
            <div>{{ swich_text }}</div>
            <div v-if="swich_text == '更多'" class="tips-text">提示：无需记录详细病例信息则无需点击“更多”，可直接点击下一步</div>
          </div>
        </van-form>
      </template>
      <!-- <Content @step="onStep" v-if="index==steps" :steps="steps" :arr="list[index].module"></Content> -->
    </div>
    <div class="btn">
      <van-button round type="primary" size="large" class="save" :disabled="disabled" native-type="submit" plain hairline @click="save('fromBtn')">保存</van-button>
      <van-button round type="info" size="large" class="up" :disabled="disabled" native-type="submit" @click="next">{{ this.steps == this.list.length - 1 ? '提交' : '下一步' }}</van-button>
    </div>
    <div class="goBack" @click="onStep('back')">
      <img src="/static/goBack.png" />
    </div>
    <mobileVerify v-if="showVerify && !this.doctorInfo.has_empower" :projectId="doctorInfo.login_project_id" :afterModifyMobile="afterModifyMobile" :doctorId="verifyDoctorId" :bankcard="verifyBankcard" :resetDoctorInfo="true" :title="'实名制验证'" :tips="'实名制手机号需和银行卡预留手机号一致'"></mobileVerify>

    <!-- 项目关闭公告 -->
    <close-notice></close-notice>

    <!-- 医生冻结弹窗 -->
    <froze-notice v-if="get_froze_status"></froze-notice>

    <input-notice v-if="showBzkyPopup" @close="showBzkyPopup = false" :desc="currentExplainDescription"></input-notice>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import { checkCellphone, isIdCardNo } from '@/utils/utils.js'
import Configure from './configure.vue'
import Steps from '@/components/steps.vue'
import Content from './content.vue'
import closeNotice from '@/components/closeNotice.vue'
import mobileVerify from '@/components/mobileVerify.vue'
import frozeNotice from '@/components/frozeNotice.vue'
import inputNotice from '@/components/inputNotice.vue'
import { getForm, getFollowForm, subschemeAgre, schemeAgre, Finish, submit, followSubmit, checkPatient, projectDoctor, getLinkScheme, changeScheme, getPreRecordDetail, isShowReason } from '@/api/api'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { getAge, getSexById } from '@/utils/utils.js'
import WordVoice from './word_voice.vue'
export default {
  name: '',
  components: {
    Steps,
    Content,
    Configure,
    mobileVerify,
    WordVoice,
    closeNotice,
    frozeNotice,
    inputNotice
  },
  data() {
    return {
      show: false,
      disabled: false,
      steps: null,
      list: [],
      record_id: null,
      agreement: false,
      phase: 1,
      patientType: '身份证', //证件类型
      verifyDoctorId: null,
      verifyBankcard: '',
      showVerify: false,
      showSave: true,
      remark: '', // 审核失败原因
      is_resign: false, //判断是否要重签劳务协议
      case_id: '', //病例id
      short_name: '', //@判断是哪个项目(肺癌科研/精锐计划)
      getCloseStatus: null, //获取项目是否关闭状态
      swich_text: '',
      ifEchoInfo: false, //靶向治疗二期判断是否需要回显患者信息
      subscheme_id: '', //靶向治疗二期子方案id会切换
      preDetail: {}, //肺肠安好项目预填信息
      showOtherDisease: false, //定制化金字塔项目病例疾病名称为其他的时候显示其他疾病名称从‘其他实体瘤’改为‘其他’并显示在列表
      bzkyNabPConfigOptions: [], //白紫科研定制化Nab-P用法用量（mg/m2）配置选项
      bzkyWeekConsumptionConfigOptions: [], //白紫科研定制化每几周给药一次（选项））配置选项
      showBzkyPopup: false, //白紫科研定制化提示弹窗
      currentExplainDescription: '', //配置项提示弹窗
      isShowReason: 0, //白紫科研定制化是否展示其他治疗原因
      template_id: null,
      is_followup: null,
      patient_name: '',
      patient_idsn: null,
      follow_id: null
    }
  },
  computed: {
    ...mapState(['userId', 'doctor_id', 'doctorInfo', 'basicInformation', 'ifClosed', 'scheme_name']),
    ...mapGetters(['get_froze_status']),
    arr() {
      // if(this.short_name)
      console.log('this.list[this.steps].module', this.list, this.steps)
      return this.list[this.steps].module
    },
    BMIData() {
      let input = this.list[this.steps].module[0].input
      let index = input.findIndex(ele => ele.label == 'BMI指数' || ele.label == 'BMI')
      if (index != -1) {
        let heightIndex = input.findIndex(ele => ele.label.indexOf('身高') != -1)
        let weightIndex = input.findIndex(ele => ele.label.indexOf('体重') != -1)
        let height = parseFloat(this.arr[0].input[heightIndex].value)
        let weight = parseFloat(this.arr[0].input[weightIndex].value)
        height = height > 10 ? height / 100 : height
        let result = weight / (height * height)
        if (result) {
          result = result.toFixed(1)
        } else {
          result = ''
        }
        this.list[this.steps].module[0].input[index].value = result
      }
    }
  },
  created() {
    this.phase = this.$route.query.phase ? this.$route.query.phase : 1
    this.patientType = this.$route.query.patient_type ? this.$route.query.patient_type : '身份证'
    this.record_id = this.$route.query.record_id ? this.$route.query.record_id : ''
    this.remark = this.$route.query.remark ? this.$route.query.remark : ''
    this.short_name = this.basicInformation.projects[0].short_name
    this.template_id = this.$route.query.template_id ? this.$route.query.template_id : null
    this.is_followup = this.$route.query.is_followup ? this.$route.query.is_followup : null
    this.patient_name = this.$route.query.patient_name ? this.$route.query.patient_name : ''
    this.patient_idsn = this.$route.query.patient_idsn ? this.$route.query.patient_idsn : null
    this.follow_id = this.$route.query.follow_id ? this.$route.query.follow_id : null
    this.init()
  },
  mounted() {
    if (this.is_followup != 1 && this.short_name == 'bxzleq') {
      this.getSchemeName()
    }
    if (this.$route.query.if_next == 'true' && this.short_name == 'iga') {
      Dialog.alert({
        message: '随访过程中，患者信息如有改变，医生可以自行修改'
      }).then(() => {})
    }
    console.log()
    // console.log(this.arr,'this.arr');
  },
  methods: {
    ...mapMutations(['setClosePop']),
    ...mapActions(['SET_REG_TYPE', 'GET_IF_CLOSED', 'GET_IF_FROZEN']),
    setBMIData() {
      let input = this.list[this.steps].module[0].input
      let index = input.findIndex(ele => ele.label == 'BMI指数' || ele.label == 'BMI')
      console.log(index, 'index')
      if (index != -1) {
        let heightIndex = input.findIndex(ele => ele.label.indexOf('身高') != -1)
        let weightIndex = input.findIndex(ele => ele.label.indexOf('体重') != -1)
        let height = parseFloat(this.arr[0].input[heightIndex].value)
        let weight = parseFloat(this.arr[0].input[weightIndex].value)
        height = height > 10 ? height / 100 : height
        let result = weight / (height * height)
        result = result.toFixed(1)
        console.log(result, 'result')
        this.list[this.steps].module[0].input[index].value = result
      }
    },
    echoInfo() {
      //靶向治疗二期回显患者基础信息
      for (let i in this.list[this.steps].module[0].input) {
        if (this.list[this.steps].module[0].input[i].name == 'name') {
          this.list[this.steps].module[0].input[i].value = localStorage.patientName
        }
        if (this.list[this.steps].module[0].input[i].name == 'idsn') {
          this.list[this.steps].module[0].input[i].value = localStorage.patientIdsn
        }
        if (this.list[this.steps].module[0].input[i].name == 'gender') {
          this.list[this.steps].module[0].input[i].value = localStorage.patientGender
        }
        if (this.list[this.steps].module[0].input[i].name == 'age') {
          this.list[this.steps].module[0].input[i].value = localStorage.patientAge
        }
        if (this.list[this.steps].module[0].input[i].label == 'ECOG评分') {
          this.list[this.steps].module[0].input[i].value = localStorage.patientEcog
        }
      }
    },
    handleShowBzkyPopup(item) {
      this.currentExplainDescription = item.explain_description
      this.showBzkyPopup = true
    },
    async switchScheme() {
      //靶向治疗二期切换方案
      // 更改方案
      let patient_name = ''
      let patient_idsn = ''
      let patient_gender = ''
      let patient_age = ''
      let patient_ecog = ''
      console.log('this.list[this.steps].module[0].input:', this.list[this.steps].module[0].input)
      // return
      for (let i in this.list[this.steps].module[0].input) {
        if (this.list[this.steps].module[0].input[i].name == 'name') {
          patient_name = this.list[this.steps].module[0].input[i].value
        }
        if (this.list[this.steps].module[0].input[i].name == 'idsn') {
          patient_idsn = this.list[this.steps].module[0].input[i].value
        }
        if (this.list[this.steps].module[0].input[i].name == 'gender') {
          patient_gender = this.list[this.steps].module[0].input[i].value
        }
        if (this.list[this.steps].module[0].input[i].name == 'age') {
          patient_age = this.list[this.steps].module[0].input[i].value
        }
        if (this.list[this.steps].module[0].input[i].label == 'ECOG评分') {
          patient_ecog = this.list[this.steps].module[0].input[i].value
        }
        // this.list[this.steps].module[0].input[i].value = ""
      }
      localStorage.setItem('patientName', patient_name)
      localStorage.setItem('patientIdsn', patient_idsn)
      localStorage.setItem('patientGender', patient_gender)
      localStorage.setItem('patientAge', patient_age)
      localStorage.setItem('patientEcog', patient_ecog)
      console.log('subscheme_id:', this.$route.query.subscheme_id)
      // return
      await changeScheme({
        type: this.swich_text == '更多' ? 'special' : 'normal',
        project_doctor_id: this.doctor_id,
        record_id: this.$route.query.if_next == true ? '' : this.record_id ? this.record_id : this.$route.query.record_id,
        old_subscheme_id: this.$route.query.subscheme_id
      }).then(res => {
        console.log('res:', res)
        this.ifEchoInfo = true
        this.subscheme_id = res
        this.init(this.subscheme_id)
      })
      this.swich_text = this.swich_text == '更多' ? '收起内容' : '更多'
    },
    getRelationShow(itm) {
      // console.log(itm,'itm88888888888', itm.label, itm.name);
      let allList = []
      for (let i in this.list) {
        let moduleList = this.list[i].module
        for (let j in moduleList) {
          let inputList = moduleList[j].input
          for (let k in inputList) {
            allList.push(inputList[k])
          }
        }
      }
      if (itm.asso && itm.asso.length > 0) {
        let isshow = false
        for (let i in itm.asso) {
          for (let j in allList) {
            if (allList[j].name.indexOf(itm.asso[i].name) != '-1') {
              if (itm.asso[i].type == 'daterange') {
                if (itm.asso[i].value == allList[j].value.day) {
                  isshow = true
                } else {
                  itm.isShow = false
                  isshow = false
                  return false
                }
              } else if (itm.asso[i].type == 'checkbox') {
                if (allList[j].value.indexOf(itm.asso[i].value) != -1) {
                  if (this.short_name === 'bzky' && itm.label === '其他超时治疗原因') {
                    if (this.isShowReason === 0) {
                      itm.require = 0
                      itm.isShow = false
                      return false
                    } else {
                      itm.require = 1
                      itm.isShow = true
                      return true
                    }
                  } else {
                    isshow = true
                  }
                  // isshow = true
                } else {
                  // 切换多选选项时清空连带的关联选项的值 (止吐中的多选会连带出配置项 )
                  if (itm.type == 'configure') {
                    for (let i in itm.value[0]) {
                      if (itm.value[0][i].type == 'text') {
                        itm.value[0][i].value = ''
                      }
                      if (itm.value[0][i].type == 'radio') {
                        itm.value[0][i].value = ''
                      }
                      if (itm.value[0][i].type == 'date') {
                        // console.log("配置项：",itm.value[0][i])
                        itm.value[0][i].value = ''
                      }
                      if (itm.value[0][i].type == 'file') {
                        itm.value[0][i].value = []
                      }
                      if (itm.value[0][i].type == 'checkbox') {
                        while (itm.value[0][i].value.length > 0) {
                          itm.value.pop()
                        }
                      }
                    }
                  }
                  if (itm.type == 'radio') {
                    itm.value = ''
                  }
                  if (itm.type == 'text') {
                    if (this.short_name === 'bzky' && itm.label === '其他超时治疗原因') {
                      if (this.isShowReason === 0) {
                        itm.require = 0
                      } else {
                        itm.require = 1
                      }
                    }
                    itm.value = ''
                  }
                  if (itm.type == 'file') {
                    // console.log("文件",itm)
                    itm.value = []
                  }
                  if (itm.type == 'checkbox') {
                    // console.log("多选",itm)
                    while (itm.value.length > 0) {
                      itm.value.pop()
                    }
                  }
                  if (itm.type == 'date') {
                    itm.value = ''
                  }
                  itm.isShow = false
                  isshow = false
                  return false
                }
              } else {
                if (this.short_name === 'jzt') {
                  if (itm.label === '其他疾病名称' && itm.asso[i].value === '其他实体瘤' && this.showOtherDisease) {
                    isshow = true
                    itm.isShow = true
                    return true
                  }
                }
                if (itm.asso[i].value == allList[j].value) {
                  isshow = true
                } else {
                  // 切换选项时清空连带的关联选项的值
                  if (itm.type == 'radio') {
                    itm.value = ''
                  }
                  if (itm.type == 'text') {
                    itm.value = ''
                  }
                  if (itm.type == 'file') {
                    console.log('文件', itm)
                    itm.value = []
                  }
                  if (itm.type == 'checkbox') {
                    // console.log("多选",itm)
                    while (itm.value.length > 0) {
                      itm.value.pop()
                    }
                  }
                  if (itm.type == 'date') {
                    itm.value = ''
                  }
                  itm.isShow = false
                  isshow = false
                  return false
                }
              }
            }
          }
        }
        if (isshow) {
          itm.isShow = true
          return true
        } else {
          itm.isShow = false
          return false
        }
      } else {
        if (this.short_name === 'jzt') {
          if (itm.label === '疾病名称' && itm.value === '其他') {
            this.showOtherDisease = true
          }
        }
        if (this.short_name === 'bzky' && (itm.label === '超时治疗原因' || itm.label === '超时证明')) {
          if (this.isShowReason === 0) {
            itm.require = 0
            itm.isShow = false
            return false
          } else {
            if (itm.type == 'file' && itm.value[0] === '') {
              // console.log("文件",itm)
              itm.value = []
            }
            if (itm.type == 'checkbox') {
              // console.log("多选",itm)
              while (itm.value.length === 1 && itm.value[0] === null) {
                itm.value.pop()
              }
            }
            if (itm.label === '超时治疗原因') {
              itm.require = 1
            }
          }
        }
        itm.isShow = true
        return true
      }
    },
    onIdsn(event, name) {
      //根据身份证获取性别和年龄
      console.log('eeee', event, name)
      let input = this.list[this.steps].module[0].input
      if (name.indexOf('诊断') != -1) {
        let index = input.findIndex(ele => ele.label.indexOf('诊疗信息') != -1)
        if (index != -1) this.list[this.steps].module[0].input[index].value = event
        if (name.indexOf('删除') != -1) {
          this.showSave = false
          this.save()
        }
      } else if (name.indexOf('处方单') != -1) {
        let index = input.findIndex(ele => ele.label.indexOf('处方单') != -1 && ele.type != 'file')
        if (index != -1) this.list[this.steps].module[0].input[index].value = event
        if (name.indexOf('删除') != -1) {
          this.showSave = false
          this.save()
        }
      } else {
        checkPatient({
          idsn: event,
          phase: this.$route.query.phase ? this.$route.query.phase : 1,
          project_doctor_id: this.doctor_id
        })
          .then(res => {
            let index = input.findIndex(ele => ele.name == 'gender')
            let sex = getSexById(event)
            if (index != -1) this.list[this.steps].module[0].input[index].value = sex
            let age = getAge(event)
            index = input.findIndex(ele => ele.name == 'age')
            if (index != -1) this.list[this.steps].module[0].input[index].value = age
            this.disabled = false
          })
          .catch(err => {
            Dialog.alert({
              message: err.response.data.message,
              confirmButtonColor: '#4B99FF',
              title: '温馨提示'
            }).then(() => {
              this.disabled = true
            })
          })
      }
    },
    async getSchemeName() {
      let res = await subschemeAgre({
        params: {
          id: this.$route.query.subscheme_id,
          doctor_id: this.userId
        }
      })
      if (this.short_name == 'bxzleq') {
        //靶向治疗二期方案一显示更多方案二显示收起内容
        if (res.name == '肠癌方案一' || res.name == '方案一') {
          this.swich_text = '更多'
        } else {
          this.swich_text = '收起内容'
        }
      }
    },
    getIsShowReason(res) {
      this.isShowReason = res
    },
    bzkyCustomPickConfirm(itm) {
      if (this.short_name === 'bzky') {
        if (itm.label === '疾病名称') {
          let { tempNabPOptions, tempWeekConsumptionOptions } = this.getBzkyOptions(itm.value, this.bzkyNabPConfigOptions, this.bzkyWeekConsumptionConfigOptions)
          console.log('this.arr', this.arr)
          this.arr.forEach(bzkyItem => {
            if (bzkyItem.module_name === '本周期【Nab-P治疗方案】') {
              bzkyItem.input.forEach(bzkyInputItem => {
                if (bzkyInputItem.type === 'radio' || (bzkyInputItem.type === 'text' && bzkyInputItem.asso && bzkyInputItem.asso[0].value === '是')) {
                  if (bzkyInputItem.label === 'Nab-P用法用量（mg/m2）') {
                    bzkyInputItem.value = ''
                    if (tempNabPOptions.length === 0) {
                      bzkyInputItem.type = 'text'
                      bzkyInputItem.placeholder = '请输入Nab-P用法用量'
                      bzkyInputItem.options = []
                    } else {
                      bzkyInputItem.type = 'radio'
                      bzkyInputItem.placeholder = null
                      bzkyInputItem.options = tempNabPOptions
                    }
                  }
                  if (bzkyInputItem.label === '每几周给药一次（选项）') {
                    bzkyInputItem.value = ''
                    if (tempWeekConsumptionOptions.length === 0) {
                      bzkyInputItem.type = 'text'
                      bzkyInputItem.placeholder = '请输入每几周给药一次'
                      bzkyInputItem.options = []
                    } else {
                      bzkyInputItem.type = 'radio'
                      bzkyInputItem.placeholder = null
                      bzkyInputItem.options = tempWeekConsumptionOptions
                    }
                  }
                }
              })
            }
          })
        }
      }
    },
    //白紫科研定制化选项
    getBzkyOptions(value, tempNabPOptions = [], tempWeekConsumptionOptions = []) {
      switch (value) {
        case '肺癌':
          console.log('this is 肺癌')
          tempNabPOptions = [100]
          tempWeekConsumptionOptions = [1]
          break
        case '乳腺癌':
          console.log('this is 乳腺癌')
          tempNabPOptions = [100, 125, 260]
          tempWeekConsumptionOptions = [1]
          break
        case '胰腺癌':
          console.log('this is 胰腺癌')
          tempNabPOptions = [125]
          tempWeekConsumptionOptions = [1]
          break
        case '鼻咽癌':
          console.log('this is 鼻咽癌')
          tempNabPOptions = [100, 140, 260]
          tempWeekConsumptionOptions = [1]
          break
        case '膀胱癌':
          console.log('this is 膀胱癌')
          tempNabPOptions = [260]
          tempWeekConsumptionOptions = [3]
          break
        case '卵巢癌':
        case '宫颈癌':
        case '黑色素瘤':
        case '胆道恶性肿瘤':
          console.log('this is 卵巢癌 宫颈癌 黑色素瘤 胆道恶性肿瘤')
          tempNabPOptions = [260]
          tempWeekConsumptionOptions = [4]
          break
        case '其他':
          console.log('其他')
          tempNabPOptions = []
          tempWeekConsumptionOptions = []
        default:
          break
      }
      return {
        tempNabPOptions,
        tempWeekConsumptionOptions
      }
    },
    async init(subscheme_id) {
      console.log('axwn record_id', this.$route.query.record_id)
      if (this.$route.query.phase && this.$route.query.phase > 1) {
        this.record_id = ''
      }
      let bzkyRequireFlag = false
      if (this.is_followup && this.is_followup == 1) {
        try {
          this.list = await getFollowForm({
            params: {
              template_id: this.template_id,
              follow_id: this.follow_id
            }
          })
          console.log(this.follow_id)
          console.log(this.list, 'this.list')
          this.$store.commit('setFormList', this.list)
          this.show = true
        } catch (error) {
          Dialog.alert({
            title: err.response.data.message,
            confirmButtonColor: '#4B99FF',
            title: '温馨提示'
          }).then(() => {
            this.$router.replace({
              path: '/me'
            })
          })
        }
        if (this.$route.query.steps) {
          this.steps = this.list.length - 1
        } else {
          this.steps = 0
        }
      } else {
        try {
          this.list = await getForm({
            params: {
              doctor_id: this.userId,
              scheme_id: this.$route.query.scheme_id,
              subscheme_id: subscheme_id ? subscheme_id : this.$route.query.subscheme_id,
              phase: this.$route.query.phase ? this.$route.query.phase : 1,
              // record_id: this.record_id,
              record_id: this.$route.query.if_next == true ? '' : this.record_id ? this.record_id : this.$route.query.record_id,
              patient_id: this.$route.query.phase && this.$route.query.phase > 1 ? this.$route.query.patient_id : '',
              project_doctor_id: this.doctor_id
            }
          })
          if (this.short_name === 'bzky') {
            this.list.forEach(item => {
              if (item.step_name === '临床诊断') {
                let tempValue
                item.module.forEach(bzkyItem => {
                  //若疾病类型已经保存为其他，则初始化时讲白紫科研的Nab-P用法用量（mg/m2）和 每几周给药一次（选项）置为输入框
                  if (bzkyItem.module_name === '临床诊断') {
                    bzkyItem.input.forEach(bzkyInputItem => {
                      if (bzkyInputItem.label === '疾病名称') {
                        tempValue = bzkyInputItem.value
                        console.log('tempValue', tempValue)
                      }
                    })
                  }
                  if (bzkyItem.module_name === '本周期【Nab-P治疗方案】') {
                    console.log('bzkyItem.input123123', bzkyItem.input)
                    let { tempNabPOptions, tempWeekConsumptionOptions } = this.getBzkyOptions(tempValue, this.bzkyNabPConfigOptions, this.bzkyWeekConsumptionConfigOptions)
                    bzkyItem.input.forEach(bzkyInputItem => {
                      if (bzkyInputItem.type === 'radio') {
                        if (bzkyInputItem.label === 'Nab-P用法用量（mg/m2）') {
                          if (tempValue && tempValue === '其他') {
                            console.log('bzkyInputItem', bzkyInputItem)
                            bzkyInputItem.type = 'text'
                            bzkyInputItem.placeholder = '请输入Nab-P用法用量'
                            bzkyInputItem.options = []
                          } else {
                            bzkyInputItem.placeholder = null
                            bzkyInputItem.type = 'radio'
                            if (tempValue) {
                              bzkyInputItem.options = tempNabPOptions
                            } else {
                              bzkyInputItem.options = bzkyInputItem.options
                            }
                          }
                          this.bzkyNabPConfigOptions = bzkyInputItem.options
                          console.log('this.bzkyNabPConfigOptions', this.bzkyNabPConfigOptions)
                        }
                        if (bzkyInputItem.label === '每几周给药一次（选项）') {
                          if (tempValue && tempValue === '其他') {
                            bzkyInputItem.type = 'text'
                            bzkyInputItem.placeholder = '请输入Nab-P用法用量'
                            bzkyInputItem.options = []
                          } else {
                            bzkyInputItem.placeholder = null
                            bzkyInputItem.type = 'radio'
                            if (tempValue) {
                              bzkyInputItem.options = tempWeekConsumptionOptions
                            } else {
                              bzkyInputItem.options = bzkyInputItem.options
                            }
                          }
                          this.bzkyNabPConfigOptions = bzkyInputItem.options
                        }
                      }
                    })
                  }
                })
              }
              if (item.step_name === '治疗方案') {
                item.module.forEach(bzkyItem => {
                  bzkyItem.input.forEach(bzkyInputItem => {
                    if (bzkyInputItem.label === '用药时间') {
                      if (bzkyInputItem.value) {
                        isShowReason({
                          record_id: this.record_id ? this.record_id : this.$route.query.record_id,
                          date: bzkyInputItem.value
                        }).then(res => {
                          bzkyInputItem.require = 1
                          this.isShowReason = res
                          console.log('res21312313123', res)
                        })
                      }
                    }
                    if (this.isShowReason === 1 && bzkyInputItem.label === '超时治疗原因') {
                      bzkyInputItem.require = 1
                      if (bzkyInputItem.value === '其他') {
                        bzkyRequireFlag = true
                      }
                    }
                    if (bzkyRequireFlag && bzkyInputItem.label === '其他超时治疗原因') {
                      bzkyInputItem.require = 1
                    }
                  })
                })
              }
            })
          }
          if (this.short_name === 'iga') {
            this.list.forEach(item => {
              console.log('🚀 ~ file: case.vue:772 ~ init ~ item:', item)
              if (item.step_name === '体格检查') {
                item.module.forEach(igaItem => {
                  console.log('🚀 ~ file: case.vue:775 ~ init ~ igaItem:', igaItem)
                  igaItem.input.forEach(igaInputItem => {
                    console.log('🚀 ~ file: case.vue:777 ~ init ~ igaInputItem:', igaInputItem)
                    if (igaInputItem.type === 'radio' && igaInputItem.options.includes('未见异常')) {
                      igaInputItem.value = '未见异常'
                    }
                  })
                })
              }
            })
          }
          console.log('this.list每一步骤的配置信息', this.list) //每一步骤的配置信息
          this.$store.commit('setFormList', this.list)
          this.show = true
        } catch (err) {
          Dialog.alert({
            title: err.response.data.message,
            confirmButtonColor: '#4B99FF',
            title: '温馨提示'
          }).then(() => {
            this.$router.replace({
              path: '/me'
            })
          })
        }
        if (this.$route.query.steps) {
          this.steps = this.list.length - 1
        } else {
          this.steps = 0
        }
        if (this.short_name == 'bxzleq' && this.ifEchoInfo && this.steps == 0) {
          this.echoInfo() //靶向治疗二期切换方案时需要回显患者信息
        }
        let res = null
        if (this.$route.query.subscheme_id && this.$route.query.subscheme_id > 0) {
          res = await subschemeAgre({
            params: {
              id: this.$route.query.subscheme_id,
              doctor_id: this.userId
            }
          })
          console.log('setSchemeName', res.name)
          this.$store.commit('setSchemeName', res.name)
        } else {
          res = await schemeAgre({
            params: {
              id: this.$route.query.scheme_id,
              doctor_id: this.userId
            }
          })
          console.log('schemeAgre:', res)
          this.$store.commit('setSchemeName', res.name)
        }
        if (res.need_sign == 1) {
          this.agreement = true
        } else {
          // 判断是否要重新签署劳务协议 若返回值是need_resign == 1则重签
          if (res.need_resign == 1) {
            this.agreement = true
            this.is_resign = true
          } else {
            this.agreement = false
            this.is_resign = false
          }

          console.log('重签劳务协议返回值:', this.is_resign)
        }
        if (this.record_id && this.short_name == 'fcah') {
          console.log('xxxx:返回来获取预填信息')
          await getPreRecordDetail({
            //肺肠安好项目获取周期预填信息
            record_id: this.record_id
          }).then(res => {
            console.log('返回来获取预填信息res:', res)
            this.preDetail = res
          })
        }
      }
    },
    async save(flag) {
      this.GET_IF_FROZEN() //若医生被冻结则显示冻结弹窗
      this.disabled = true
      if (flag == 'fromBtn') {
        await this.GET_IF_CLOSED()
          .then(() => {
            if (this.ifClosed) this.setClosePop(true) //打开项目关闭弹框
          })
          .catch(err => {
            Toast(err.response.data.message)
          })
      }
      if (this.ifClosed) return //项目已关闭则返回
      this.getCloseStatus = await getLinkScheme({
        params: {
          project_doctor_id: this.doctor_id
        }
      })
      console.log('关闭状态', this.getCloseStatus.is_close_record) //  1表示关闭；0表示未关闭

      let data = []
      let list = this.list[this.steps].module
      for (let i in list) {
        let input = list[i].input
        for (let j in input) {
          if (input[j].isShow) {
            //看是不是显示了
            if (input[j].type == 'configure') {
              //添加的配置项 配置项默认不必填
              let childs = []
              let childValue = input[j].value
              for (let k in childValue) {
                let childList = childValue[k]
                let childItem = []
                for (let q in childList) {
                  //循环每个配置项里面的小的
                  let req = {
                    value: childList[q].value,
                    name: childList[q].name
                  }
                  childItem.push(req)
                }
                childs.push(childItem)
              }

              let req = {
                value: '',
                name: input[j].name,
                child: childs
              }
              data.push(req)
            } else {
              let req = {
                value: input[j].value,
                name: input[j].name
              }
              data.push(req)
            }
          }
        }
      }

      console.log('$$$$$$$保存', data)
      // 点击保存按钮请求
      if (this.is_followup && this.is_followup == 1) {
        followSubmit({
          follow_id: this.follow_id,
          record_id: this.$route.query.record_id,
          step: this.steps,
          save: 1,
          data: data
        })
          .then(res => {
            this.disabled = false
            if (this.showSave) {
              Toast('保存成功')
            }
            console.log(3333333333)
            // this.$store.dispatch('SET_TABNAME','随访信息')
            this.$store.commit('setTabName', '随访信息')
            this.$router.replace({
              //若关闭创建随访，则点确认后跳转到个人中心页
              path: '/progress',
              query: {
                activeName: '随访信息'
              }
            })
            // this
            this.$nextTick(() => {
              this.showSave = true
            })
          })
          .catch(err => {
            this.disabled = false
            Dialog.alert({
              message: err.response.data.message, //捕获提示省份范围外
              confirmButtonColor: '#4B99FF',
              title: '温馨提示'
            }).then(() => {})
          })
      } else {
        submit({
          doctor_id: this.userId,
          phase: this.phase,
          subscheme_id: this.subscheme_id ? this.subscheme_id : this.$route.query.subscheme_id, //靶向治疗二期的子方案id会切换不能直接用路由上的
          scheme_id: this.$route.query.scheme_id,
          data: data,
          step: this.steps,
          record_id: this.$route.query.if_next == true ? '' : this.record_id ? this.record_id : this.$route.query.record_id,
          patient_id: this.phase == 1 ? '' : this.$route.query.patient_id,
          save: 1,
          old_subscheme_id: this.$route.query.subscheme_id
        })
          .then(res => {
            this.disabled = false
            console.log(res, 'res')
            this.record_id = res.record.id
            this.case_id = res.record.id
            if (this.showSave) {
              Toast('保存成功')
            }
            this.$nextTick(() => {
              this.showSave = true
            })
            this.$router.replace({
              //若关闭创建病例，则点确认后跳转到个人中心页
              path: '/progress'
            })
          })
          .catch(err => {
            this.disabled = false
            //  1表示关闭；0表示未关闭
            if (this.getCloseStatus.is_close_record == 1) {
              Dialog.alert({
                message: err.response.data.message,
                confirmButtonColor: '#4B99FF',
                title: '温馨提示'
              }).then(() => {
                this.$router.replace({
                  //若关闭创建病例，则点确认后跳转到个人中心页
                  path: '/me'
                })
              })
            } else {
              Dialog.alert({
                message: err.response.data.message, //捕获提示省份范围外
                confirmButtonColor: '#4B99FF',
                title: '温馨提示'
              }).then(() => {})
            }
          })
        if (this.record_id && this.short_name == 'fcah') {
          console.log('xxxx:返回来获取预填信息')
          await getPreRecordDetail({
            //肺肠安好项目获取周期预填信息
            record_id: this.record_id
          }).then(res => {
            this.preDetail = res
          })
        }
      }
    },
    async next() {
      // console.log(this.steps,'steps');
      this.GET_IF_FROZEN() //若医生被冻结则显示冻结弹窗
      this.disabled = true
      this.GET_IF_CLOSED()
        .then(() => {
          if (this.ifClosed) this.setClosePop(true) //打开项目关闭弹框
        })
        .catch(err => {
          Toast(err.response.data.message)
        })
      if (this.ifClosed) return
      this.getCloseStatus = await getLinkScheme({
        params: {
          project_doctor_id: this.doctor_id
        }
      })
      console.log('是否关闭创建病例通道', this.getCloseStatus)
      let data = []
      let list = this.list[this.steps].module
      for (let i in list) {
        let input = list[i].input
        for (let j in input) {
          if (input[j].isShow) {
            //看是不是显示了
            if (input[j].type == 'configure') {
              //添加的配置项 配置项默认不必填
              let childs = []
              let childValue = input[j].value
              for (let k in childValue) {
                let childList = childValue[k]
                let childItem = []
                console.log('childList', childList)
                for (let q in childList) {
                  //循环每个配置项里面的小的
                  if (childList[q].asso && childList[q].asso.length > 0) {
                    if (childList[q].isShow && childList[q].require == 1) {
                      if (childList[q].type == 'checkbox' || childList[q].type == 'file') {
                        if (childList[q].value.length <= 0) {
                          Toast('请完善' + childList[q].label)
                          this.disabled = false
                          return false
                        }
                      } else {
                        console.log(childList[q])
                        if (!childList[q].value) {
                          Toast('请完善' + childList[q].label)
                          this.disabled = false
                          return false
                        }
                      }
                    }
                  } else {
                    if (childList[q].require == 1) {
                      if (childList[q].type == 'checkbox' || childList[q].type == 'file') {
                        if (childList[q].value.length <= 0) {
                          Toast('请完善' + childList[q].label)
                          this.disabled = false
                          return false
                        }
                      } else {
                        console.log(childList[q])
                        if (!childList[q].value) {
                          Toast('请完善' + childList[q].label)
                          this.disabled = false
                          return false
                        }
                      }
                    }
                  }
                  console.log('childList[q]', childList[q])
                  let req = {
                    value: childList[q].value,
                    name: childList[q].name
                  }
                  childItem.push(req)
                }
                console.log('childItem', childItem)
                childs.push(childItem)
              }

              let req = {
                value: '',
                name: input[j].name,
                child: childs
              }
              data.push(req)
            } else {
              if (input[j].type == 'file') {
                let sum = input[j].value.length
                if (input[j].require == 1 && sum < 1) {
                  Toast('请完善' + input[j].label)
                  this.disabled = false
                  return false
                }
                if (input[j].upload_file_min_count) {
                  let min = parseInt(input[j].upload_file_min_count)
                  if (sum > 0 && sum < min) {
                    Toast('请至少上传' + min + '张')
                    this.disabled = false
                    return false
                  }
                }
              }
              if (input[j].asso && input[j].asso.length > 0) {
                //关联项
                if (input[j].isShow) {
                  if (input[j].require == 1) {
                    if (input[j].type == 'checkbox' || input[j].type == 'file') {
                      if (input[j].value.length <= 0) {
                        Toast('请完善' + input[j].label)
                        this.disabled = false
                        return false
                      }
                    } else {
                      if (!input[j].value) {
                        Toast('请完善' + input[j].label)
                        this.disabled = false
                        return false
                      }
                    }
                  }
                }
              }
              if (input[j].type != 'configure' && !input[j].asso) {
                //普通的配置项
                if (input[j].require == 1) {
                  if (input[j].type == 'checkbox' || input[j].type == 'file') {
                    if (input[j].value.length <= 0) {
                      Toast('请完善' + input[j].label)
                      this.disabled = false
                      return false
                    }
                  } else {
                    if (!input[j].value) {
                      Toast('请完善' + input[j].label)
                      this.disabled = false
                      return false
                    } else {
                      if (input[j].limit == 'idsn' && this.patientType === '身份证' && !isIdCardNo(input[j].value)) {
                        Toast('请输入正确的身份证号')
                        this.disabled = false
                        return false
                      } else if (input[j].limit == 'mobile' && !checkCellphone(input[j].value)) {
                        Toast('请输入正确的手机号')
                        this.disabled = false
                        return false
                      }
                    }
                  }
                }
              }
              let req = {
                value: input[j].value,
                name: input[j].name
              }
              data.push(req)
            }
          }
        }
      }

      if (this.steps == 0) {
        if (this.$route.query.phase && this.$route.query.phase > 1) {
          this.record_id = ''
        }
      }
      console.log('steps,list', this.steps, this.list)
      if (this.steps == this.list.length - 1) {
        //在最后一步弹出四要素认证
        if (this.$route.query.is_followup && this.$route.query.is_followup == 1) {
          Dialog.confirm({
            message: '提交审核后不可修改，是否确认提交审核？',
            title: '确定提交',
            showCancelButton: true,
            cancelButtonText: '我再想想',
            confirmButtonColor: '#4B99FF',
            confirmButtonText: '确定提交'
          })
            .then(() => {
              this.SET_REG_TYPE(null)
              localStorage.removeItem('measure')
              followSubmit({
                record_id: this.$route.query.record_id,
                follow_id: this.follow_id,
                step: this.steps,
                save: 0,
                data: data
              })
                .then(res => {
                  this.disabled = false
                  Toast.clear()
                  Toast('提交成功')
                  this.$nextTick(() => {
                    this.showSave = true
                  })
                  console.log(444444444)
                  // this.$store.dispatch('SET_TABNAME','随访信息')
                  this.$store.commit('setTabName', '随访信息')
                  this.$router.replace({
                    path: '/progress',
                    query: {
                      activeName: '随访信息'
                    }
                  })
                })
                .catch(err => {
                  this.disabled = false
                  Dialog.alert({
                    message: err.response.data.message,
                    confirmButtonColor: '#4B99FF',
                    title: '温馨提示'
                  })
                })
            })
            .catch(() => {
              this.disabled = false
            })
        } else {
          console.log('this.doctorInfo', this.doctorInfo)
          projectDoctor().then(res => {
            //获取can_mobile_verify
            let verifyFlag = !this.doctorInfo || res.is_mobile_verify != 1
            if (res.can_mobile_verify == true && !this.doctorInfo.has_empower && verifyFlag) {
              this.verifyDoctorId = this.userId
              this.verifyBankcard = this.doctorInfo ? this.doctorInfo.bankcard : ''
              this.showVerify = true
              this.disabled = false
              return false
            }
          })
        }
      }
      console.log('data@@@@@@@下一步', data)
      console.log('old_subscheme_id:下一步', this.$route.query.subscheme_id)
      // 点击下一步提交按钮
      if (this.$route.query.is_followup && this.$route.query.is_followup == 1) {
        if (this.steps !== this.list.length - 1) {
          console.log(this.$route.query.follow_id, 'this.$route.query.follow_id,')
          followSubmit({
            record_id: this.$route.query.record_id,
            follow_id: this.follow_id,
            step: this.steps,
            save: 0,
            data: data
          })
            .then(res => {
              this.disabled = false
              let data = {
                follow_id: res.follow_info.id,
                type: 'up'
              }
              this.follow_id = res.follow_info.id
              // console.log(res.follow_info.follow_id);
              // console.log(this.follow_id,'this.follow_id');
              this.onStep(data)
              this.$nextTick(() => {
                this.showSave = true
              })
            })
            .catch(err => {
              this.disabled = false
              Dialog.alert({
                message: err.response.data.message,
                confirmButtonColor: '#4B99FF',
                title: '温馨提示'
              })
            })
        }
      } else {
        submit({
          doctor_id: this.userId,
          phase: this.phase,
          subscheme_id: this.subscheme_id ? this.subscheme_id : this.$route.query.subscheme_id,
          scheme_id: this.$route.query.scheme_id,
          data: data,
          step: this.steps,
          record_id: this.$route.query.if_next == true ? '' : this.record_id ? this.record_id : this.$route.query.record_id,
          patient_id: this.phase == 1 ? '' : this.$route.query.patient_id,
          old_subscheme_id: this.$route.query.subscheme_id
        })
          .then(res => {
            console.log('submit_res:', res)
            this.disabled = false
            let data = {
              record_id: res.record.id,
              type: 'up'
            }
            this.record_id = res.record.id
            this.onStep(data)
          })
          .catch(err => {
            this.disabled = false
            console.log('提示错误信息', this.getCloseStatus.is_close_record)
            //  1表示关闭；0表示未关闭
            if (this.getCloseStatus.is_close_record == 1) {
              Dialog.alert({
                message: err.response.data.message,
                confirmButtonColor: '#4B99FF',
                title: '温馨提示'
              }).then(() => {
                this.$router.replace({
                  //若关闭创建病例，则点确认后跳转到个人中心页
                  path: '/me'
                })
              })
            } else {
              Dialog.alert({
                message: err.response.data.message, //捕获提示省份范围外
                confirmButtonColor: '#4B99FF',
                title: '温馨提示'
              }).then(() => {})
            }
          })
      }

      if (this.record_id && this.short_name == 'fcah') {
        await getPreRecordDetail({
          //肺肠安好项目获取周期预填信息
          record_id: this.record_id
        }).then(res => {
          this.preDetail = res
        })
      }
    },
    onStep(direction) {
      console.log(direction, this.steps, '666666')
      this.show = false
      if (direction.record_id) this.record_id = direction.record_id
      if (direction.follow_id) this.follow_id = direction.follow_id
      if (direction == 'back') {
        if (this.steps == 0) {
          localStorage.removeItem('patientName')
          localStorage.removeItem('patientIdsn')
          localStorage.removeItem('patientGender')
          localStorage.removeItem('patientAge')
          localStorage.removeItem('patient_ecog')
          this.$router.replace({
            path: 'progress'
          })
        } else {
          this.steps--
          // 简单解决步骤切换时content组件内容刷新问题
          this.$nextTick(() => {
            this.show = true
          })
        }
      }
      if (direction.type == 'up') {
        if (this.steps == this.list.length - 1) {
          if (this.agreement) {
            // @若is_resign为true则需要提示重新签署服务协议（劳务协议）
            if (this.is_resign) {
              Dialog.alert({
                title: '温馨提示',
                message: '需要您重新签署服务协议',
                confirmButtonColor: '#4B99FF'
              }).then(() => {
                this.$router.replace({
                  path: '/case_agreement',
                  query: {
                    record_id: this.record_id,
                    scheme_id: this.$route.query.scheme_id,
                    subscheme_id: this.$route.query.subscheme_id,
                    phase: this.$route.query.phase ? this.$route.query.phase : 1,
                    is_resign: true
                  }
                })
              })
            } else {
              this.$router.replace({
                path: '/case_agreement',
                query: {
                  record_id: this.record_id,
                  scheme_id: this.$route.query.scheme_id,
                  subscheme_id: this.$route.query.subscheme_id,
                  phase: this.$route.query.phase ? this.$route.query.phase : 1,
                  is_resign: false
                }
              })
            }
          } else {
            Dialog.alert({
              message: '提交审核后不可修改，是否确认提交审核？',
              title: '确定提交',
              showCancelButton: true,
              cancelButtonText: '我再想想',
              confirmButtonColor: '#4B99FF',
              confirmButtonText: '确定提交'
            })
              .then(() => {
                this.SET_REG_TYPE(null)
                localStorage.removeItem('measure')
                if (this.$route.query.is_followup && this.$route.query.is_followup == 1) {
                  // this.$router.replace({
                  //     path: '/progress',
                  //     query:"随访信息"
                  // })
                } else {
                  Finish({
                    record_id: this.record_id
                  })
                    .then(res => {
                      Toast.clear()
                      Toast('提交成功')
                      this.$router.replace({
                        path: '/progress'
                      })
                    })
                    .catch(err => {
                      Toast(err.response.data.message)
                    })
                }
              })
              .catch(() => {})
          }
        } else {
          this.steps++
          // 简单解决步骤切换时content组件内容刷新问题
          this.$nextTick(() => {
            this.show = true
          })
        }
      }
    },
    afterModifyMobile(d) {
      this.showVerify = false
    },
    async checkIfClose() {
      await this.GET_IF_CLOSED() //获取ifClosed
      if (this.ifClosed) this.setClosePop(true) //打开项目关闭弹框
    },
    nextDisabled(ifDisabled) {
      console.log('下一步')
      this.disabled = ifDisabled
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  padding-left: 11px;
  box-sizing: border-box;
  font-size: 15px;
  color: #232323;
  margin-bottom: 13px;
  text-align: left;
}
.formBox {
  padding: 0 11px;
  padding-bottom: calc(155px + constant(safe-area-inset-bottom));
  padding-bottom: calc(155px + env(safe-area-inset-bottom));
  box-sizing: border-box;
  .from {
    margin-bottom: 30px;
    .no-border-bottom {
      &::after {
        border-bottom: none !important;
      }
      ::v-deep .van-cell::after {
        border-bottom: none !important;
      }
    }
    .bzky-text-btn {
      padding: 0 16px;
      font-size: 14px;
      color: #4b99ff;
      font-family: PingFangSC-regular;
      p {
        margin-top: 0;
        padding-bottom: 10px;
        border-bottom: 1px solid #ebedf0;
      }
      .black {
        color: #333;
      }
    }
    .more_text {
      font-size: 18px;
      color: #4b99ff;
      font-family: PingFangSC-Medium;
      margin-top: 20px;
      .tips-text {
        margin-top: 10px;
        font-size: 15px;
        line-height: 1.8;
        color: #646566;
        font-weight: lighter;
        padding: 0 10px;
      }
    }
  }
}

.goBack {
  position: fixed;
  right: 11px;
  bottom: 150px;
  height: 45px;
  width: 45px;

  img {
    height: 100%;
    width: 100%;
  }
}

.addCase {
  width: 100%;
  .btn {
    .save {
      color: #292929;
    }
  }
  .top {
    width: 100%;
    height: 77px;
    background-image: linear-gradient(to right, #5b9bf0, #3d87ea);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 14px;

    .steps {
      width: 100%;
      margin-bottom: 17px;
    }
  }
}
</style>
