<template>
    <div class="case_content" >
        <van-field autosize type="textarea" v-model="item.value" label="短信验证码" placeholder="请输入短信验证码" :required="item.require==1" >
            <template #button>
                <van-button size="mini" icon="volume" style="border:none" :class="isPlaying ? 'play-btn' : '' " @click="playVideo"/>
            </template>
        </van-field>
    </div>
</template>
<script>
import { getwordToAudit } from '@/api/api';
import { Toast } from "vant";
export default {
    props: ['item'],
    data() {
        return {
            isPlaying:false,
            vidioUrl: '',
        }
    },
    mounted(){
        this.getVideo()
    },
    methods:{
        playVideo(){
            if(this.isPlaying) {
                this.isPlaying = false
                this.$refs.audio.pause()
            } else {
                this.isPlaying = true
                this.$refs.audio.play()
            }
        },
        async getVideo() {
            await getwordToAudit({params:{
                text: this.item.value}
            }).then(res => {
                this.vidioUrl = res
            }).catch(err => {
                Toast(err.response.data.message)
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.van-button::before{
        background-color:transparent;
    }
.play-btn {
    color: #2788FF;
}
.case_content {
        position: relative;
    }

    .case_child {
        padding: 0 30px;
    }

    .case_content::after {
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        right: 16px;
        bottom: 0;
        left: 16px;
        border-bottom: 1px solid #ebedf0;
        -webkit-transform: scaleY(.5);
        transform: scaleY(.5);
    }
</style>